<script lang="tsx" setup>
const state = shallowReactive({
  src: '',
  storeLink: ''
})

const apps = Object.freeze([
  ['mdi:google', 'https://www.youtube-nocookie.com/embed/vlvvCusvPrQ', 'GMAIL'],
  ['solar:smartphone-2-bold', 'https://www.youtube-nocookie.com/embed/m7CgM__Fgdg', 'GMAIL_MOBILE'],
  ['mdi:outlook', `https://www.youtube-nocookie.com/embed/${device.isApple ? 'XXzTI9t5HxM' : 'NCqzriPL3l8'}`, 'OUTLOOK'],
  ['solar:question-circle-bold', 'https://vocal.email/help']
] as const)

function show(index: number, callback: VoidFunction) {
  const [, link, storeKey] = apps[index]

  if (!link.includes('youtube'))
    return window.open(link, '_blank')

  state.src = link
  state.storeLink = import.meta.env[`VITE_APP_STORE_LINK_${storeKey}`]
  callback()
}
</script>

<template>
  <VideoButton v-slot="ui" :src="state.src" :store-url="state.storeLink" :is-youtube="true">
    <section v-once class="gap-3 flex items-center">
      <button
        v-for="([icon], index) in apps" :key="index"
        class="w-10 h-10 p-1.5 rounded-5px flex-center text-xl text-primary-50 bg-hex-4A94FB hover:bg-primary-400"
        @click="show(index, ui.show)"
      >
        <Icon :name="icon" />
      </button>
    </section>
  </VideoButton>
</template>
